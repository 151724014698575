import React, { useState, useEffect } from "react";
import { Loader } from "lucide-react";

const SearchToken = ({ onSelectAddress }) => {
  const [query, setQuery] = useState("");
  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetching tokens data from the API on component mount
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await fetch("https://rubyexplorer.com/searchtoken");
        const data = await response.json();
        setTokens(data.result ? data.result : []);
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    };
    fetchTokens();
  }, []);

  // Filtering tokens based on query input
  useEffect(() => {
    if (tokens.length > 0 && query) {
      setLoading(true);

      const filtered = tokens.filter((token) =>
        query.length < 10
          ? token.contractname.toLowerCase().includes(query.toLowerCase()) // Match contractname for queries shorter than 10
          : token.contract_address.toLowerCase().includes(query.toLowerCase()) // Match contract_address for queries 10 characters or more
      );

      setFilteredTokens(filtered);
      setLoading(false);
    } else {
      setFilteredTokens([]); // Clear the filtered list if there's no query
    }
  }, [query, tokens]);

  // Function to handle selection of a token
  const handleSelectToken = async (contractAddress) => {
    try {
   
      const url = `https://rubyexplorer.com/importtokenaddress/${onSelectAddress}/${contractAddress}`;
      const response = await fetch(url);

      const data = await response.json();
      console.log("data",data)
      if (data.status) {
        // Call the onSelectAddress function with the selected contract address
        alert(`Token Add Successfully`);
        window.location.reload();

      } else {
        alert("Failed to import token address");
      }
    } catch (error) {
      console.error("Error importing token address:", error);
      alert("An error occurred while selecting the token");
    }
  };

  return (
    <div className="p-4 max-w-lg mx-auto">
      {/* Search input */}
      <input
        type="text"
        placeholder="Token name /Contract Address.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="border border-gray-300 rounded-md p-2 w-full md:w-[500px] mx-auto"
      />
      
      {/* Loading indicator */}
      {loading && <Loader className="animate-spin mx-auto mt-2" />}

      {/* Display filtered tokens if the query matches any token */}
      {query && !loading && filteredTokens.length > 0 && (
        <div className="mt-4">
          {filteredTokens.map((token) => (
            <div key={token.contract_address} className="mb-2 border rounded-md p-4 flex justify-between items-center">
              {/* Contract name on the left */}
              <p className="text-lg font-bold">
                {token.contractname} {token.contract_address.slice(-20)}
                <button
                  className="receive-button"
                  onClick={() => handleSelectToken(token.contract_address)} // Call the handleSelectToken function
                >
                  Add Token
                </button>
              </p>
            </div>
          ))}
        </div>
      )}

      {/* If no matching tokens found */}
      {query && !loading && filteredTokens.length === 0 && (
        <p className="mt-4 text-center text-gray-500">No tokens found</p>
      )}
    </div>
  );
};

export default SearchToken;
