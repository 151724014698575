import React from "react";

const RubyWeb3Docs = () => {
    
  return (
    <div
      style={{
        padding: "40px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1>RubyWeb3 Module Documentation</h1>
      <p>
        The <strong>rubyweb3</strong> module is a Node.js package for managing
        Ruby . Below, you will find details on how to install and use this
        module.
      </p>

      <h2>Installation</h2>
      <p>To install the module, use the following command:</p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>npm install rubyweb3</code>
      </pre>

      <h2>Usage</h2>
      <p>Import the required methods from the `rubyweb3` module:</p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`const { createwallet, getBalance } = require('rubyweb3');`}</code>
      </pre>

      <h3>1. Create Ruby wallet</h3>
      <p>
        Use the <code>createwallet</code> function to create wallets:
      </p>

      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
     { createwallet } = require('rubyweb3')

async function createrubywallet() {
  try {
    const result = await createwallet();
    console.log('successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>2. get Balance Ruby wallet</h3>
      <p>
        Use the <code>getBalance</code> function to get Balance:
      </p>

      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
     { getBalance } = require('rubyweb3')

async function getrubyBalance(address) {
  try {
    const result = await getBalance(address);
    console.log('successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>3. Sending Ruby</h3>
      <p>
        Use the <code>sendRuby</code> function to send Ruby to a recipient
        address:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
             { sendRuby } = require('rubyweb3')

const privateKey = 'your-private-key';
const toAddr = 'recipient-address';
const rubyAmount = 10;

async function send() {
  try {
    const result = await sendRuby(privateKey, toAddr, rubyAmount);
    console.log('Transaction successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>4. get transaction data by Hash</h3>
      <p>
        Use the <code>Hash </code> function to get Balance:
      </p>

      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
     { transactionHash } = require('rubyweb3')

async function transactionHashex(Hash) {
  try {
    const result = await transactionHash(Hash);
    console.log('successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>5. Sending Ruby Token</h3>
      <p>
        Use the <code>sendtoken</code> function to send Ruby token to a
        recipient address:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
             { sendtoken } = require('rubyweb3')

const privateKey = 'your-private-key';
const RecipientAddr = 'recipient-address';
const contractAddr = 'contract-address';
const amount = 10;

async function sendtoken_test() {
  try {
    const result = await sendtoken(privateKey, RecipientAddr,contractAddr, amount);
    console.log('Transaction successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>6. Getting Token Balance</h3>
      <p>
        Use the <code>tokenbalance</code> function to retrieve the token balance
        of a specific address:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const address = 'address';
const contractAddr = 'contract-address';

async function getBalanceExample() {
  try {
    const balance = await tokenbalance(address,contractAddr);
    console.log('Balance:', balance);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>7. Getting All Transction data</h3>
      <p>
        Use the <code>getAllTransction</code> function to All Transction :
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const pageNumber = 'pageNumber';
const pageLength = 'pageLength';

async function getAllTransctionExample() {
  try {
    const result = await getAllTransction(pageNumber,pageLength);
    console.log('successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>8. Getting user Transction data</h3>
      <p>
        Use the <code>getuserTransction</code> function to user Transction of a
        specific address :
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const pageNumber = 'pageNumber';
const pageLength = 'pageLength';
const address = 'address';

async function getuserTransctionExample() {
  try {
    const result = await getuserTransction(address,pageNumber,pageLength);
    console.log('successful:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>9. Check address </h3>
      <p>
        Use the <code>checkaddress</code> function to check address:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const address = 'address';

async function getBalanceExample() {
  try {
    const result = await checkaddress(address);
    console.log('result:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>10. getAddress by privateKey </h3>
      <p>
        Use the <code>getAddressbyprivateKey</code> function to get address using privateKey:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const privateKey = 'privateKey';

async function getAddressbyprivateKeyExample() {
  try {
    const result = await getAddressbyprivateKey(privateKey);
    console.log('result:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>


      <h3>11. get Latest Block Number </h3>
      <p>
        Use the <code>getLatestBlockNumber</code> get Latest Block Number:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`

async function getLatestBlockNumberExample() {
  try {
    const result = await getLatestBlockNumber();
    console.log('result:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h3>12. get  Block data </h3>
      <p>
        Use the <code>getBlockNumberdata</code> get Block data by Block Number:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const BlockNumber = 'BlockNumber';

async function getBlockNumberdataExample(BlockNumber) {
  try {
    const result = await getBlockNumberdata(BlockNumber);
    console.log('result:', result);
  } catch (error) {
    console.error('Error:', error.message);
  }
}
        `}</code>
      </pre>

      <h2>Example Workflow</h2>
      <p>
        Here's a complete example that combines both sending Ruby and
        checking the balance:
      </p>
      <pre
        style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}
      >
        <code>{`
const { sendRuby, getBalance } = require('rubyweb3');

async function runExample() {
  const privateKey = 'your-private-key';
  const toAddr = 'recipient-address';
  const rubyAmount = 10;

  // Sending Ruby
  try {
    const sendResult = await sendRuby(privateKey, toAddr, rubyAmount);
    console.log('Ruby sent successfully:', sendResult);
  } catch (error) {
    console.error('Send Error:', error.message);
  }

  // Checking Balance
  try {
    const balance = await getBalance(toAddr);
    console.log('Balance:', balance);
  } catch (error) {
    console.error('Balance Error:', error.message);
  }
}

runExample();
        `}</code>
      </pre>

      <h2>Additional Information</h2>
      <ul>
        <li>
          Make sure to replace <code>your-private-key</code> and{" "}
          <code>recipient-address</code> with actual values.
        </li>
        <li>
          Ensure that you have network connectivity and proper permissions to
          interact with the Ruby.
        </li>
      </ul>
    </div>
  );
};

export default RubyWeb3Docs;
