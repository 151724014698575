import React, { useState, useEffect } from "react";
import "../Styles/ApiDocumentation.css";

const walletapiDocumentation = [
  {
    id: 1,
    title: "Create Wallet",
    endpoint: "/createwallet",
    method: "GET",
    description: "Create a new Wallet.",
    requestExample: `
      fetch('/createwallet', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
    response: `
      {
        "status": true,
        "result": {
          "address": "rf76a80b19e40ae7fb4ad59bc44b031a9faab711c",
          "privateKey": "2baf481b36c8bbfa38e4f1ee22610af395d3be502c64361b435d20ac874fae79"
        }
      }
    `,
  },
  {
    id: 2,
    title: "Get Balance",
    endpoint: "/getBalance/:address",
    method: "GET",
    description: "Get the balance of a specific address.",
    requestExample: `
      fetch('/getBalance/rc85781fb5c5346e542ea87cc2518723e8e975d92', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
    response: `
      {
        "status": true,
        "result": "11111249.31899"
      }
    `,
  }, {
    id: 2,
    title: "Get User transaction ",
    endpoint: "/getuserTransction/:address/:pageNumber/:pagelength",
    method: "GET",
    description: "Get the user all transaction of a specific address.",
    requestExample: `
      fetch('/getuserTransction/rbc1a08731ae6b8e549d837079d5fac3e8453a887/1/20', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
    response: `
      {
    "status": true,
    "result": [
        {
            "blockHash": "r0e06685703227c1c37bdcd537be1b20f6cf4b9ab573c03b8a61a706675b756da",
            "blockNumber": 109038,
            "from": "rc85781fb5c5346e542ea87cc2518723e8e975d92",
            "gas": 2e-13,
            "gasPrice": 1e-8,
            "gasLimit": 3e-11,
            "gasUsed": 2.1e-14,
            "fee": 0.00021,
            "hash": "r234474e89190d782e0735b89c5156bcc3ac20bb169b456dc35e50254b718d3df",
            "input": "0x",
            "nonce": 26851,
            "to": "rbc1a08731ae6b8e549d837079d5fac3e8453a887",
            "transactionIndex": 0,
            "type": "0",
            "value": 17.44,
            "chainId": 18215,
            "v": 36465,
            "r": "r1655104b0a35778a3038d98a4383db0b92626a9891389dcfc4066e3f0b27175d",
            "s": "r2e2505480d14d59a26e8fa04665844b5d13402bd770c1f046b5760ef838cbbac",
            "status": 1,
            "createdAt": "2024-07-09T07:56:39.096Z",
            "updatedAt": "2024-07-09T07:56:39.096Z",
            "__v": 0
        }
    ],
    "balance": "17.44",
    "trx_length": 1,
    "Gasused": 0.00021,
    "latestBlockNumber": 109074
}
    `,
  }, {
    id: 1,
    title: "get Top accounts ",
    endpoint: "/getTopaccounts",
    method: "GET",
    description: "get Blockchain all top address.",
    requestExample: `
    fetch('/getAddress_By_privateKey', {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  `,
    response: `
    {
    "status": true,
    "address": "r00f16d7da39670B9cd45D47Fbc9e6D0E06929E39"
}
  `,
  }, {
    id: 1,
    title: "get address ",
    endpoint: "/getTopaccounts/pageNumber/pageLength",
    method: "GET",
    description: "get wallet address by private Key.",
    requestExample: `
    fetch('/getTopaccounts/1/2', {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  `,
    response: `
   {
    "status": true,
    "result": [
        {
            "address": "r01679731e194e3d4d47d687b4ab1976334d0112f",
            "__v": 0,
            "balance": 231818752.999349
        },
        {
            "address": "rc85781fb5c5346e542ea87cc2518723e8e975d92",
            "__v": 0,
            "balance": 10553427.15132
        }
    ],
    "pagination": {
        "totalTransactions": 27870,
        "totalPages": 13935,
        "currentPage": 1
    }
}
  `,
  },

];
const transactionapiDocumentation = [

  {
    id: 3,
    title: "Send",
    endpoint: "/send/:privateKey/:receiverAddress/:amount",
    method: "GET",
    description: "Send Ruby to another address.",
    requestExample: `
        fetch('/send/:privateKey/:receiverAddress/:amount', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
        {
          "status": true,
          "result": "Transaction successful"
        }
      `,
  },

  {
    id: 5,
    title: "Get Transaction Details",
    endpoint: "/getTransactionData/:transactionHash",
    method: "GET",
    description: "Fetch details of a specific transaction by hash.",
    requestExample: `
        fetch('/getTransactionData/0x1234567890abcdef', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
        {
    "status": true,
    "result": {
        "_id": "668ced41d38c6e3302848b87",
        "blockHash": "r3b04a1c40aebb6c3f2da9e0f933cae0a69ea3685df447f7db6adb27e895f5a80",
        "blockNumber": 109039,
        "from": "rc85781fb5c5346e542ea87cc2518723e8e975d92",
        "gas": 2e-13,
        "gasPrice": 1e-8,
        "gasLimit": 3e-11,
        "gasUsed": 2.1e-14,
        "fee": 0.00021,
        "hash": "rf299086942ae254522288d0d54c921aca214fe7d3e8bbc9f61e4b8beff3419ef",
        "input": "0x",
        "nonce": 26852,
        "to": "r9417d6be36de0accb0d25730598bfe37dd39ac0d",
        "transactionIndex": 0,
        "type": "0",
        "value": 62.61,
        "chainId": 18215,
        "v": 36465,
        "r": "r18d7721525715bfb3fc36feed9540198515b99fdc18a52076ad991e144590044",
        "s": "r1abf93d25204ac70cf1dcc5801aa459999138ebc8d10634b8064b3234fa920a6",
        "status": 1,
        "createdAt": "2024-07-09T07:56:49.094Z",
        "updatedAt": "2024-07-09T07:56:49.094Z",
        "__v": 0
    }
}
      `,
  },
  {
    id: 5,
    title: "Get  All Transaction Details",
    endpoint: "/getAllTransction/:pageNumber/:pagelength",
    method: "GET",
    description: "Fetch details of all transaction.",
    requestExample: `
        fetch('/getAllTransction/1/1', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
       {
    "status": true,
    "result": [
        {
            "blockHash": "r0e63ec725aa61180c83fcf6b501e1438742792dc31a6c8351cae42f111ee6736",
            "blockNumber": 109108,
            "from": "r07ade1e3de65aa30cea38d2ca832d87e8861b437",
            "gas": 2e-13,
            "gasPrice": 1e-8,
            "gasLimit": 3e-11,
            "gasUsed": 2.1e-14,
            "fee": 0.00021,
            "hash": "rf8104b2d2b229d5cc91f25f3c5428610e44797a85c35726a08d1118f2591e27b",
            "input": "0x",
            "nonce": 12979,
            "to": "r6e502dedd6fc56a84b30e490b94db98a64db494a",
            "transactionIndex": 0,
            "type": "0",
            "value": 43.005,
            "chainId": 18215,
            "v": 36466,
            "r": "rb9b15c0b94b5f843e977ad6372feacfa96c877ec79d4e0cc432294595f0b53a3",
            "s": "r71664da9ecbfa2373b9a102da5f1aec0a8623b9e471e2922760486816294aff4",
            "status": 1,
            "createdAt": "2024-07-09T08:08:19.127Z",
            "updatedAt": "2024-07-09T08:08:19.127Z",
            "__v": 0
        }
    ],
    "pagination": {
        "totalTransactions": 52277,
        "totalPages": 52277,
        "currentPage": "1"
    }
}
}
      `,
  }, {
    id: 6,
    title: "Get  Txs chart Details",
    endpoint: "/getTransactionGraph",
    method: "GET",
    description: "Fetch details of Txs chart.",
    requestExample: `
      fetch('/getTransactionGraph', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
    response: `
    {
    "status": true,
    "result": [
        {
            "_id": 0,
            "count": 73,
            "createdAt": "2024-07-10T00:04:12.821Z"
        },
        {
            "_id": 1,
            "count": 231,
            "createdAt": "2024-07-10T01:00:03.027Z"
        },
        {
            "_id": 2,
            "count": 303,
            "createdAt": "2024-07-10T02:02:13.233Z"
        },
        {
            "_id": 3,
            "count": 185,
            "createdAt": "2024-07-10T03:00:03.427Z"
        },
        {
            "_id": 4,
            "count": 135,
            "createdAt": "2024-07-10T04:00:23.605Z"
        },
        {
            "_id": 5,
            "count": 295,
            "createdAt": "2024-07-09T05:48:28.716Z"
        },
        {
            "_id": 6,
            "count": 204,
            "createdAt": "2024-07-09T06:00:28.731Z"
        },
        {
            "_id": 7,
            "count": 233,
            "createdAt": "2024-07-09T07:00:08.908Z"
        },
        {
            "_id": 8,
            "count": 194,
            "createdAt": "2024-07-09T08:00:09.109Z"
        },
        {
            "_id": 9,
            "count": 209,
            "createdAt": "2024-07-09T09:00:09.296Z"
        },
        {
            "_id": 10,
            "count": 152,
            "createdAt": "2024-07-09T10:00:29.491Z"
        },
        {
            "_id": 11,
            "count": 228,
            "createdAt": "2024-07-09T11:00:29.673Z"
        },
        {
            "_id": 12,
            "count": 210,
            "createdAt": "2024-07-09T12:00:09.830Z"
        },
        {
            "_id": 13,
            "count": 153,
            "createdAt": "2024-07-09T13:00:29.987Z"
        },
        {
            "_id": 14,
            "count": 127,
            "createdAt": "2024-07-09T14:00:10.130Z"
        },
        {
            "_id": 15,
            "count": 144,
            "createdAt": "2024-07-09T15:00:21.058Z"
        },
        {
            "_id": 16,
            "count": 149,
            "createdAt": "2024-07-09T16:00:01.190Z"
        },
        {
            "_id": 17,
            "count": 86,
            "createdAt": "2024-07-09T17:00:31.361Z"
        },
        {
            "_id": 18,
            "count": 66,
            "createdAt": "2024-07-09T18:00:01.704Z"
        },
        {
            "_id": 19,
            "count": 71,
            "createdAt": "2024-07-09T19:01:01.842Z"
        },
        {
            "_id": 20,
            "count": 42,
            "createdAt": "2024-07-09T20:00:02.070Z"
        },
        {
            "_id": 21,
            "count": 27,
            "createdAt": "2024-07-09T21:00:02.275Z"
        },
        {
            "_id": 22,
            "count": 25,
            "createdAt": "2024-07-09T22:06:52.439Z"
        },
        {
            "_id": 23,
            "count": 44,
            "createdAt": "2024-07-09T23:04:22.641Z"
        }
    ]

  }
    `,
  },
];

const tokentransactionapi = [

  {
    id: 3,
    title: "Token Send",
    endpoint: "/sendtoken/:privateKey/:RecipientAddr/:contractAddr/:amount",
    method: "GET",
    description: "Send Token to another address.",
    requestExample: `
        fetch('/send/:privateKey/:receiverAddress/:contractAddr/:amount', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
      {"status":true,
      "result":"r641d761077769bfe8823890de1abfdb0c3c0f225f6c7146ab7699f3934025ac0"}
      `,
  },

  {
    id: 5,
    title: "Get TOken Transaction Details",
    endpoint: "/tokenbalance/:address/:contractAddr",
    method: "GET",
    description: "Fetch details of a specific transaction by hash.",
    requestExample: `
        fetch('/tokenbalance/address/contractAddr', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
        {"status":true
        ,"balance":"50"}
}
      `,
  },
  {
    id: 5,
    title: "Get Token Details",
    endpoint: "/getTokenData/:contractAddress",
    method: "GET",
    description: "Fetch details of Token.",
    requestExample: `
        fetch('/getTokenData/contractAddress', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
       {"status":true,
       "result":{"contractAddress":"r47af91acd0b3d6fc043a97b3c2e09dc9a56c899c",
       "name":"RShibainu ",
       "symbol":"RShibainu ",
       "totalSupply":"300000",
       "owner":"r5bb2ad9c9187d3fc33a3d773ede396d617ae5bee",
       "contractType":"RBC20",
       "decimals":18,
      }
}
      `,
  }, {
    id: 6,
    title: "Get  Tokens ",
    endpoint: "/getTokens",
    method: "GET",
    description: "Fetch details of Txs chart.",
    requestExample: `
      fetch('/getTokens', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
    `,
  },
];
const BlockapiDocumentation = [


  {
    id: 4,
    title: "Get Latest Blocks",
    endpoint: "/getLatestBlocks",
    method: "GET",
    description: "Retrieve the latest blocks on the blockchain.",
    requestExample: `
        fetch('/getLatestBlocks', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
        {
          "status": true,
          "result": [
            {
              "blockNumber": 123456,
              "timestamp": "2024-07-08T12:34:56Z",
              "transactions": 20,
              "miner": "0xMinerAddress"
            },
            {
              "blockNumber": 123455,
              "timestamp": "2024-07-08T12:30:00Z",
              "transactions": 15,
              "miner": "0xAnotherMinerAddress"
            }
          ]
        }
      `,
  }, {
    id: 4,
    title: "Get Block Data",
    endpoint: "/getBlockNumberdata/109129",
    method: "GET",
    description: "Retrieve the  block data by Block Number on the blockchain.",
    requestExample: `
        fetch('/getLatestBlocks', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
       {
    "status": true,
    "blockdata": {
        "baseFeePerGas": 7,
        "difficulty": 1,
        "gasLimit": 30000000,
        "gasUsed": 21000,
        "nonce": 0,
        "number": 109129,
        "size": 728,
        "timestamp": 1720512696,
        "totalDifficulty": 198675,
        "extraData": "rd883010d0f846765746888676f312e32312e36856c696e7578000000
        00000000371bdb32ed9b5f6aaeb7bb85c5e24e0bc488a08feaffd
        5a7d51100a71a50edd022b108636e12faedd8d3b7d587b6f4f19d
        13ea0ddf0c8ef9f1dd42fd9715777101",
        "hash": "rd92db4ebb9ed824d3092da86b0e071af3302a0f7c7ab99c2219bf1b6d088ef80",
        "miner": "R42a29C0D567CA327C059B4C5D93E5e0ed3429196",
        "mixHash": "r0000000000000000000000000000000000000000000000000000000000000000",
        "parentHash": "rc693870bf9ef6b88cfe0ee89de947437cc081a0d005a22190a365881931178e9",
        "receiptsRoot": "r056b23fbba480696b65fe5a59b8f2148a1299103c4f57df839233af2cf4ca2d2",
        "sha3Uncles": "r1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347",
        "stateRoot": "r0b4ed5675e0942552ed0510927ed4bfd400a89cd44fd121cdd44790b4988019c"
    },
    "transactionData": [
        {
            "blockHash": "rd92db4ebb9ed824d3092da86b0e071af3302a0f7c7ab99c2219bf1b6d088ef80",
            "blockNumber": 109129,
            "from": "r07ade1e3de65aa30cea38d2ca832d87e8861b437",
            "gas": 200000,
            "gasPrice": 10000000000,
            "hash": "r5c805c7056e90b746d700e961a3f7f894efa3c1e2353f5dc0b573a86f0d35f41",
            "input": "0x",
            "nonce": 12995,
            "to": "ra3c59916ccaa367d44e083f9b5707bac4534b73c",
            "transactionIndex": 0,
            "value": "0.06",
            "type": 0,
            "chainId": 18215,
            "v": 36465,
            "r": "rf46487722a8c3254cc5fc5e266f1dad5f7abbf4fcd806f1b43110dce81823594",
            "s": "r062c2357883330fc40feb994245cdba5ee58e3613db8f0b875869b24906476cc",
            "fee": "0.002"
        }
    ]
}
      `,
  },

  // Add more API documentation objects here...
];
const ContractapiDocumentation = [
  {
    id: 1,
    title: "Get All Contract",
    endpoint: "/getContracts",
    method: "GET",
    description: "Retrieve the all contract address on the blockchain.",
    requestExample: `
        fetch('/getContracts', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
        {"status":true,
        "result":[
        {
        "blockHash":"r384a332542e6a8e41debf5cfc258d220592941189f32298f19b869e845e2af38",
        "blockNumber":119240,"from":"r1584b4032c123c9406fb284b34e5b421a03a51d6",
        "gas":2.014812e-12,
        "gasPrice":1e-8,
        "gasLimit":3e-11,
        "gasUsed":2.018789e-12,
        "fee":0.02018789,
        "hash":"r047b7889bfccca511269125bd20aaae93fcff8dbb4feb534924471143afa5a3d",
        "input":"rdfjvc",
        "nonce":33,
        "transactionIndex":0,
        "type":"2",
        "value":0,
        "chainId":18215,
        "v":0,
        "r":"rb4392296b59fd8502cbaf1b7dac723bf54b6783450f91a04cd2e9c279de682fd","s":"r36a665e774e15ec78d0be1750433a2d9ba567352dc633951cab9d4b01e532f73",
        "status":1,
        "contract":"r328b1f2f3ac115d4100fe925af83f67c9e0495b3",
        "transctiontype":1,
        "createdAt":"2024-07-10T12:17:05.198Z"
        }
        ]
        }
      `,
  }, {
    id: 4,
    title: "Get getContractsData ",
    endpoint: "/getContractsData/",
    method: "GET",
    description: "Retrieve the  getContractsData by Contract address Number on the blockchain.",
    requestExample: `
        fetch('/getContractsData', {
          method: 'GET'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `,
    response: `
       {
    "status": true,
    "result": {
        "contractAddress": "0x328b1f2f3ac115d4100fe925af83f67c9e0495b3",
        "name": "RShiba ",
        "symbol": "RShiba ",
        "totalSupply": "100000",
        "owner": "r328b1f2f3ac115d4100fe925af83f67c9e0495b3",
        "decimals": 18,
        "abi": [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "_mint",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "burn",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "burnFrom",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "subtractedValue",
                        "type": "uint256"
                    }
                ],
                "name": "decreaseAllowance",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "addedValue",
                        "type": "uint256"
                    }
                ],
                "name": "increaseAllowance",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "previousOwner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "OwnershipTransferred",
                "type": "event"
            },
            {
                "inputs": [],
                "name": "renounceOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "transferOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "_decimals",
                "outputs": [
                    {
                        "internalType": "uint8",
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "_name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "_symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    }
                ],
                "name": "allowance",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "decimals",
                "outputs": [
                    {
                        "internalType": "uint8",
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "getOwner",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "owner",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "totalSupply",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
    }
}
   
    
}
      `,
  },

];
const Api = () => {
  const [selectedSection, setSelectedSection] = useState("default");
  const baseUrl = "https://rubyexplorer.com/";

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(baseUrl)
      .then(() => {
        alert(`URL copied to clipboard: ${baseUrl}`);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };
  return (
    <div className="app">
      <div className="vertical-header">
        <nav className="nav-links">
          <a
            href="#wallet"
            className={selectedSection === "wallet" ? "selected" : ""}
            onClick={() => setSelectedSection("wallet")}
          >
            Wallet
          </a>
          <a
            href="#transaction"
            className={selectedSection === "transaction" ? "selected" : ""}
            onClick={() => setSelectedSection("transaction")}
          >
            Transaction
          </a>
          <a
            href="#token"
            className={selectedSection === "token" ? "selected" : ""}
            onClick={() => setSelectedSection("token")}
          >
            Token
          </a>
          <a
            href="#Block"
            className={selectedSection === "services" ? "selected" : ""}
            onClick={() => setSelectedSection("Block")}
          >
            Block
          </a>
          <a
            href="#Contract"
            className={selectedSection === "Contract" ? "selected" : ""}
            onClick={() => setSelectedSection("Contract")}
          >
            Contract
          </a>
        </nav>
      </div>
      <div className="main-content">
        <h1>API Documentation</h1>
        <div>
          <span>BASE_URL</span>
          <span
            id="baseurl"
            className="baseurl"
            onClick={handleCopyUrl}
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline", marginLeft: "8px" }}
          >
            {baseUrl}
          </span>
        </div>

        {selectedSection === "default" && (
          <div className="api-description">
            <h2>API Overview</h2>
            <p>
              This API is designed to facilitate developers in transitioning applications from Rubyscan, as well as to support applications requiring general API and data access. It supports both GET and POST requests, enabling a wide range of functionalities and interactions.
            </p>

            <h3>Key Features</h3>
            <ul>
              <li><strong>Comprehensive Data Access:</strong> Provides access to detailed blockchain data, including transaction history, account balances, smart contract information, and more.</li>
              <li><strong>High Performance:</strong> Optimized for speed and efficiency, ensuring quick response times and reliable performance for high-traffic applications.</li>
              <li><strong>Secure Communication:</strong> Implements robust security measures, including HTTPS and API key authentication, to protect data integrity and prevent unauthorized access.</li>
              <li><strong>Scalability:</strong> Designed to handle large volumes of requests, making it suitable for both small-scale applications and large enterprise solutions.</li>
              <li><strong>Flexibility:</strong> Supports a variety of use cases with flexible data query options, allowing developers to retrieve only the data they need.</li>
            </ul>

            <h3>Supported Operations</h3>
            <ul>
              <li><strong>GET Requests:</strong>
                <ul>
                  <li>Retrieve detailed information about specific transactions, including status, value, and associated addresses.</li>
                  <li>Access comprehensive account data, including balance, transaction history, and token holdings.</li>
                  <li>Query smart contract details and execution results.</li>
                </ul>
              </li>
              <li><strong>POST Requests:</strong>
                <ul>
                  <li>Submit new transactions to the blockchain network.</li>
                  <li>Interact with smart contracts by invoking methods and passing parameters.</li>
                  <li>Batch multiple operations into a single request for efficiency.</li>
                </ul>
              </li>
            </ul>

            <h3>Use Cases</h3>
            <ul>
              <li><strong>Blockchain Explorers:</strong> Build applications that allow users to explore blockchain data, view transaction histories, and monitor network activity.</li>
              <li><strong>Wallet Integrations:</strong> Integrate blockchain data and transaction capabilities into digital wallets, providing users with up-to-date balance and transaction information.</li>
              <li><strong>Smart Contract Management:</strong> Develop tools to deploy, interact with, and monitor smart contracts, enhancing the functionality of decentralized applications (DApps).</li>
              <li><strong>Data Analytics:</strong> Create analytical tools that leverage blockchain data to provide insights, generate reports, and track trends over time.</li>
            </ul>

            <h3>Getting Started</h3>
            <p>
              To begin using the API, developers need to sign up for an API key, which is required for all requests. Detailed documentation and example code snippets are available to help you integrate the API into your applications quickly and efficiently.
            </p>

            <h3>Documentation and Support</h3>
            <p>
              Comprehensive documentation is available, covering all aspects of the API, including endpoint descriptions, request and response formats, error codes, and usage examples. Additionally, our support team is available to assist with any questions or issues you may encounter.
            </p>

            <h3>Conclusion</h3>
            <p>
              This API is a powerful tool for developers looking to transition applications from rubyscan or for those in need of general blockchain data and API support. With its robust features, high performance, and flexible operation modes, it is well-suited for a wide range of applications in the blockchain ecosystem.
            </p>
          </div>
        )}
        {selectedSection === "wallet" && (
          <div className="api-documentation">
            {walletapiDocumentation.map((api) => (
              <div key={api.id} className="api-section">
                <h2>{api.title}</h2>
                <span className="Endpoint"><strong>Endpoint:</strong> {api.endpoint}</span>
                <div className="Endpoint"><strong>Method:</strong> {api.method}</div>
                <div className="Endpoint"><strong>Description:</strong> {api.description}</div>
                <div className="Endpoint"><strong>Request Example:</strong></div>
                <pre className="requestExample">{api.requestExample}</pre>
                <span className="Endpoint"><strong>Response:</strong></span>
                <pre className="requestExample">{api.response}</pre>
              </div>
            ))}
          </div>
        )}
        {selectedSection === "transaction" && (
          <div className="api-documentation">
            {transactionapiDocumentation.map((api) => (
              <div key={api.id} className="api-section">
                <h2>{api.title}</h2>
                <span className="Endpoint"><strong>Endpoint:</strong> {api.endpoint}</span>
                <div className="Endpoint"><strong>Method:</strong> {api.method}</div>
                <div className="Endpoint"><strong>Description:</strong> {api.description}</div>
                <div className="Endpoint"><strong>Request Example:</strong></div>
                <pre className="requestExample">{api.requestExample}</pre>
                <span className="Endpoint"><strong>Response:</strong></span>
                <pre className="requestExample">{api.response}</pre>
              </div>
            ))}
          </div>
        )} {selectedSection === "Block" && (
          <div className="api-documentation">
            {BlockapiDocumentation.map((api) => (
              <div key={api.id} className="api-section">
                <h2>{api.title}</h2>
                <span className="Endpoint"><strong>Endpoint:</strong> {api.endpoint}</span>
                <div className="Endpoint"><strong>Method:</strong> {api.method}</div>
                <div className="Endpoint"><strong>Description:</strong> {api.description}</div>
                <div className="Endpoint"><strong>Request Example:</strong></div>
                <pre className="requestExample">{api.requestExample}</pre>
                <span className="Endpoint"><strong>Response:</strong></span>
                <pre className="requestExample">{api.response}</pre>
              </div>
            ))}
          </div>
        )} {selectedSection === "Contract" && (
          <div className="api-documentation">
            {ContractapiDocumentation.map((api) => (
              <div key={api.id} className="api-section">
                <h2>{api.title}</h2>
                <span className="Endpoint"><strong>Endpoint:</strong> {api.endpoint}</span>
                <div className="Endpoint"><strong>Method:</strong> {api.method}</div>
                <div className="Endpoint"><strong>Description:</strong> {api.description}</div>
                <div className="Endpoint"><strong>Request Example:</strong></div>
                <pre className="requestExample">{api.requestExample}</pre>
                <span className="Endpoint"><strong>Response:</strong></span>
                <pre className="requestExample">{api.response}</pre>
              </div>
            ))}
          </div>
        )}
         {selectedSection === "token" && (
          <div className="api-documentation">
            {tokentransactionapi.map((api) => (
              <div key={api.id} className="api-section">
                <h2>{api.title}</h2>
                <span className="Endpoint"><strong>Endpoint:</strong> {api.endpoint}</span>
                <div className="Endpoint"><strong>Method:</strong> {api.method}</div>
                <div className="Endpoint"><strong>Description:</strong> {api.description}</div>
                <div className="Endpoint"><strong>Request Example:</strong></div>
                <pre className="requestExample">{api.requestExample}</pre>
                <span className="Endpoint"><strong>Response:</strong></span>
                <pre className="requestExample">{api.response}</pre>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Api;
